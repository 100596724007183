import '../src/assets/styles/css/tailwind.css'
import '../src/assets/styles/sass/main.scss'
import '../src/assets/styles/sass/mixin.scss'
import VueApexCharts from 'vue3-apexcharts'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

export default {
  install (app) {
    app.use(VueApexCharts)
    app.use(VCalendar, {})
  }
}
