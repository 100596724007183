import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import './registerServiceWorker'
import i18n from './i18n'
import vuex from './store'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyD2qwhe7zJCg5tXddrJFtqd45F3DC9Tm8Q',
  authDomain: 'teamsecu-f002b.firebaseapp.com',
  projectId: 'teamsecu-f002b',
  storageBucket: 'teamsecu-f002b.appspot.com',
  messagingSenderId: '624998260160',
  appId: '1:624998260160:web:0544e0f03f1dd242a03c25'
}
export const db = initializeApp(firebaseConfig)
export const firestore = getFirestore()
export const functions = getFunctions()

createApp(App).use(i18n)
  .use(i18n)
  .use(vuex)
  .use(VueSvgIconPlugin, { tagName: 'icon' })
  .use(install)
  .use(rooter)
  .mount('#app')
