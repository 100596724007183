import { createWebHistory, createRouter } from 'vue-router'
import pathLoader from '../plugins/loader'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: pathLoader('home'),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: pathLoader('dashboard', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/incidents',
        name: 'Incidents',
        component: pathLoader('incidents', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/sites',
        name: 'Sites',
        component: pathLoader('sites', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/agents',
        name: 'Agents',
        component: pathLoader('agents', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/requests',
        name: 'Requests',
        component: pathLoader('requests', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/service-plug',
        name: 'Presence',
        component: pathLoader('presence', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/equipments',
        name: 'Equipments',
        component: pathLoader('equipments', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/instructions',
        name: 'Instructions',
        component: pathLoader('instructions', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/personal-administration',
        name: 'Personal',
        component: pathLoader('personal', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/administrators',
        name: 'Administrators',
        component: pathLoader('administrators', 'index'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/logout',
        name: 'Logout',
        component: pathLoader('logout', 'index'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: pathLoader('login')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // const uid = user.uid
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next() // make sure to always call next()!
  }
})

export default router
