/**
 *PROJECT Trip
 * Created by WebStorm.
 * Author Khaliq ALI<hello@kgeek.me>)
 * Date 30/12/2021
 * TIME 17:52
 **/

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {
    uid: ''
  },
  mutations: {
    logout (state) {
      state.uid = ''
    },
    UPDATE_USER_ID (state, user) {
      state.uid = user
    }
  },
  actions: {
    logOUT ({ commit }, dec) {
      commit('logout', dec)
    },
    CREATE_USER_ID ({ commit }, uid) {
      commit('UPDATE_USER_ID', uid)
    }
  },
  getters: {
    userId: state => {
      return state.uid
    }
  }
})

export default vuex
