/**
 * *
This is path loader
Just for no always import page
 **
 */
export default (path, file = false) => {
  return () => {
    return import(`../pages/${path || ''}${path ? '/' : ''}${file || 'index'}.vue`)
  }
}
