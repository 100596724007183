export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "showPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "hidePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ? "])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mot de passe"])},
  "createMyCompanyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte pour ma compagnie"])},
  "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
  "passwordErrorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre mot de passe (min: 6 caractères)"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrects"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "incidentsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidents"])},
  "sitesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])},
  "agentsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
  "agentsPersonalMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel administratif"])},
  "requestsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes"])},
  "presenceMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion présence"])},
  "equipmentsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipements"])},
  "instructionsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "administratorsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateurs"])},
  "logoutMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "inService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En service"])},
  "outService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors service"])},
  "onLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En congés"])},
  "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendu"])},
  "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleurs"])},
  "deployedEquipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipements <br>déployés"])},
  "inWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolus"])},
  "turnOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffres d’affaire"])},
  "wageCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges salariales"])},
  "incidentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’incident"])},
  "becomeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "declaredIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaré"])},
  "treatedIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traité"])},
  "goingIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "incidentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails incident"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "joinPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
  "inProgressTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement en cours"])},
  "treatIncidentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter l’incident"])},
  "incidentTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement d’incident"])},
  "leaveObservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser une observation"])},
  "goBackButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "incidentTreatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident traité avec succès"])},
  "incidentMarkedAsTreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’incident est marqué comme traité. Une notification du traitement est envoyée aux ayants droit pour un suivi coordonné. "])},
  "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "importSiteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer sites"])},
  "createSiteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un site"])},
  "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du site"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "siteResponsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable de Site"])},
  "importSiteFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier de sites"])},
  "clickToImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer ici pour importer un fichier excel"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "siteCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de site "])},
  "siteIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification du site"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "physicalAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse physique"])},
  "mailBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte postale"])},
  "longitudeCoordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées GPS / Longitude"])},
  "latitudeCoordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées GPS / Latitude"])},
  "siteDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du site"])},
  "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "contractualInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations contractuelles"])},
  "contractBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début du contrat"])},
  "contractEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du contrat"])},
  "contractAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du contrat"])},
  "monthlyPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualités"])},
  "askedAgentsNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’agents demandés"])},
  "deployedAgentsNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’agent déployés"])},
  "scannedContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat scanné"])},
  "siteContactPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de contact sur le site"])},
  "observation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "newSiteSuccessCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau site créé avec succès. Vous pouvez maintenant désigner les agents devant intervenir sur le site "])},
  "designateAgentsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désigner des agents"])},
  "laterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus tard"])},
  "agentsDesignation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation d’agents"])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
  "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périodicité"])},
  "uphillTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de montée"])},
  "beginningDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
  "addAgentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un agent"])},
  "siteDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du site"])},
  "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les détails"])},
  "currentHands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mains courantes"])},
  "siteEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipements du site"])},
  "clientMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériels client"])},
  "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout afficher"])},
  "registrationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
  "servicePlug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise de service"])},
  "siteAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents du site"])},
  "completeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "resting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au repos"])},
  "reservist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserviste"])},
  "toSuspend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre"])},
  "putOnLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre en congé"])},
  "toReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
  "inWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "addAccessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accès"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "newAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel accès"])},
  "toAdministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l’administration"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "newInstructionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle instruction"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyées"])},
  "programed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmées"])},
  "instructionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de l’ instruction"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "aboutInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de l’instruction"])},
  "entitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
  "clickChooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour choisir le fichier"])},
  "consigneeInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire(s) de l’instruction"])},
  "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrage par"])},
  "exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusions"])},
  "personalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de personnel"])},
  "geographicalRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone géographique"])},
  "sentTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure d’envoi"])},
  "sendNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer maintenant"])},
  "sendLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer ultérieurement"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "newEquipmentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel équipement"])},
  "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté disponible"])},
  "affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectés"])},
  "equipmentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’équipement"])},
  "availableNewQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité disponible"])},
  "equipmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat de l’équipement"])},
  "particularObservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation particulière"])},
  "illustrativePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image illustrative"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuf"])},
  "littleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quasi neuf"])},
  "useless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usé"])},
  "littleDamaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un peu endommagé"])},
  "importListsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer une liste"])},
  "importAgentFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier d’agents"])},
  "newAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel agent"])},
  "identificationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d’identification"])},
  "birthdayDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "birthdayAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acte de naissance"])},
  "identityCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte d’identité"])},
  "identityPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos d'identité"])},
  "residenceAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation de résidence"])},
  "childrenBirthdayAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acte de naissance des enfants"])},
  "wifeBirthdayAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acte de naissance de la femme"])},
  "weddingAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acte de mariage"])},
  "recommendationLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettre de recommandation"])},
  "criminalRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casier judiciaire"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
  "hiringDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’embauche"])},
  "contractType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contrat"])},
  "contractTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terme du contrat"])},
  "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire (en fcfa)"])},
  "agentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails agent"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le profil"])},
  "affectedEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipements affectés"])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes"])},
  "assignedSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site assigné"])},
  "takenService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise de service"])},
  "gardeDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de garde"])},
  "lastPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière position"])},
  "affectedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté affectée"])},
  "affectEquipmentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affecter un équipement"])},
  "affectAnEquipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affecter un équipement"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "affectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affecter"])},
  "errorAddAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs !"])},
  "adminAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouvel accès à l'administration a été ajouté avec succès."])},
  "noAdminAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun administrateur ajouté"])},
  "leaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de quitter TeamSecu"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "firstPasswordChanging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mot de passe"])},
  "passwordNotSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots de passe non conformes !"])},
  "passwordChangeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe modifié avec succès. Vous pouvez maintenant accéder à votre dashboard"])},
  "errorsLoginUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant ou mot de passe incorrects"])},
  "zoneChief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef zone"])},
  "contactPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de contact"])},
  "errorAddAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs obligatoires (*)"])},
  "successAgentCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouvel agent a été ajouté avec succès."])},
  "noAgentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun agent ajouté"])},
  "noSiteAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun site ajouté"])},
  "noSiteAgentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun agent désigné pour ce site"])},
  "noEquipmentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun équipement ajouté"])},
  "equipmentAddSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouvel équipement a été ajouté avec succès."])},
  "equipmentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un équipement a été modifié avec succès."])},
  "noAffectedEquipmentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun équipement affecté à cet agent"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "noNewInstructionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle instruction envoyée"])},
  "noNewProgramedInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle instruction programmée"])},
  "successAddInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle instruction a été envoyée avec succès."])},
  "noSiteAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun site assigné à cet agent"])},
  "noIncidentDeclared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun incident déclaré"])},
  "noCurrentHandDeclared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune main courante déclarée par cet agent"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evénement"])},
  "noAddedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête ajoutée"])},
  "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
  "noSiteEquipmentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun équipement affecté à ce site"])},
  "noSiteIncidentDeclared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun incident déclaré pour ce site"])},
  "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
  "otherDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres détails"])},
  "equipmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails équipement"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat"])},
  "instructionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'instruction"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "noNotificationAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
  "successAgentUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de cet agent ont été modifiées avec succès."])},
  "superiorQuantityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité supérieure à la quantité en stock. Veuillez diminuer votre quantité"])},
  "noAgentInService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun agent en service actuellement"])},
  "clientMaterialAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel client ajouté avec succès."])},
  "clientMaterialUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel client mis à jour avec succès."])},
  "noClientMaterialAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun matériel client ajouté."])},
  "noSiteCurrentHandAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune main courante ajoutée pour ce site."])},
  "siteUpdatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site modifié avec succès."])},
  "requestDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails requête"])},
  "dateAndHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date & heure"])},
  "enterAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent entrant"])},
  "outerAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent sortant"])},
  "presenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l’opération"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de mot de passe"])},
  "siteChief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef site"])},
  "fileError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille du fichier dépasse 5 Mio"])},
  "custodyPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de garde"])},
  "changeAffectation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer affectation"])},
  "successChangeAffectation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des informations d'affectation effectué avec succès"])},
  "affectationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'affectation"])},
  "noRequestAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête ajoutée"])},
  "noPresenceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune gestion de service ajoutée"])},
  "siteImportedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sites ont été importés avec succès"])},
  "agentImportedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les agents ont été importés avec succès"])},
  "siteAddError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un fichier"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleur"])},
  "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorti"])},
  "administrativePersonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel administratif"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "centralPersonalAdministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration Centrale"])},
  "affectationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails d'affectation"])},
  "addPersonalButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un personnel"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
  "noPersonalAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun personnel administratif ajouté"])},
  "errorPersonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs importants"])},
  "successPersonalAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau personnel administratif ajouté avec succès"])},
  "importPersonalFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier de personnel administratif"])},
  "supervisors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superviseurs"])},
  "responsiblePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone du responsable de site"])},
  "responsibleJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste du responsable de site"])},
  "contactPointPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone du point de contact"])},
  "contactPointJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste du point de contact"])},
  "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails d'un personnel administratif"])},
  "addZoneButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une zone"])},
  "siteManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef site"])},
  "zoneSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone d'affectation"])},
  "treatRequestButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter la requête"])},
  "requestTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement de requête"])},
  "requestTreatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête traitée avec succès"])},
  "requestMarkedAsTreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La requête est marquée comme traitée. Une notification du traitement est envoyée aux ayants droit pour un suivi coordonné. "])}
}